.update_wrapper {
  margin: 0 20px 20px 20px;
  height: 100%;
}

.update_title-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.update_form {
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  padding: clamp(1px, calc(0px + 2vw), 25px);
}

.update_part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.add_user_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: clamp(15px, 3vw, 45px);
}

.add_email {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: clamp(10px, 2vw, 25px);
}

.email_table {
  min-width: 250px;
  word-break: break-word;
}

.action_title {
  word-break: keep-all;
}

.email_delete_icon {
  border-radius: 3px;
  height: 100% !important;
  min-width: clamp(20px, 2vw, 35px);
  margin: auto;
}

.email_delete_icon:hover {
  color: white !important;
  background-color: var(--main-red);
}

.submit_button_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.update_wrapper>* {
  font-size: var(--textFontSize) !important;
}

.image_input_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid gray;
  border-radius: 5px;
  padding-right: 5px;
}

.select_file_button {
  background-color: #dfdfdf;
  text-transform: none;
  border: none;
  padding: 4px;
  border-radius: 5px;
  font-size: var(--buttonFontSize);
  padding: clamp(5px, calc(5px + 0.1vw), 15px) clamp(5px, calc(0.5vw), 25px);
}

.selected_file_name {
  font-size: var(--tableFontSize) !important;
  margin-left: 5px;
}

@media only screen and (min-width: 1000px) {
  .update_wrapper {
    width: 80%;
    margin: auto;
  }
}