.switch_wrapper {
    margin-top: 10%;
}

.switch_text {
    display: inline-block;
}

.toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 30px;
    height: 15px;
    display: inline-block;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background-color: #4caf50;
    border: 0.5px solid rgb(120, 120, 120);
    cursor: pointer;
    transition: background-color ease 0.3s;
    margin: 0 8px;
}

.toggle:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    width: 50%;
    height: 100%;
    background: #fff;
    border-radius: 50%;
}

.toggle:checked {
    background-color: #0d6efd;
}

.toggle:checked:before {
    left: 50%;
}