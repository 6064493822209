.table_wrapper {
    margin: 0 20px 20px 20px;
}

.table_main {
    padding: clamp(1px, calc(0px + 2vw), 25px);
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    margin-bottom: 20px;
}

.table_head_item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sort_icon {
    margin: 0 clamp(0px, calc(2px + 1vw), 20px) 0 0;
    cursor: pointer;
    font-size: clamp(6px, calc(5px + 1vw), 12px) !important;
}

.table_loader {
    height: 80vh !important;
}

.title-add_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head_item {
    vertical-align: middle;
    font-size: var(--tableFontSize) !important;
    padding: clamp(5px, calc(0px + 2vw), 20px) clamp(1px, calc(0px + 0.1vw), 10px) !important;
}

.filter_input {
    border: none;
    border-bottom: 1px solid var(--second-grey);
    font-size: var(--tableFontSize) !important;
    padding: 10px 0;
}

.filter_input:focus {
    outline: none !important;
    border-bottom: 1px solid var(--second-grey);
}

.table_icon {
    text-align: center;
    cursor: pointer;
    width: 50% !important;
    padding: clamp(2px, calc(2px + 0.1vw), 15px) 0 !important;
}

.body_item {
    font-size: var(--tableFontSize) !important;
    padding: clamp(0px, calc(2px + 1vw), 20px) 0 !important;
    vertical-align: middle;
}

/* first item of table */
.body_item:first-of-type {
    cursor: pointer;
    padding-left: clamp(2px, calc(-1px + 1vw), 7px) !important;
    padding-right: clamp(4px, calc(1vw), 7px) !important;
}

/* Third item of tabel */
.body_item:nth-of-type(3),
.head_item:nth-of-type(4) {
    padding-left: 10px !important;
}

.body_item:nth-of-type(2) {
    padding-right: 10px !important;
}

.body_counter,
.head_counter {
    text-align: center;
}

.table_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.action_buttons {
    text-align: center;
    vertical-align: middle;
}

.action_title {
    text-align: center;
}

.update_icon {
    display: inline;
    float: left;
}

.delete_icon {
    display: inline;
    float: right
}

.update_icon:hover {
    color: var(--main-white);
    cursor: pointer;
    background-color: var(--inevvo-blue);
    border-radius: 3px;
}

.delete_icon:hover {
    color: var(--main-white);
    cursor: pointer;
    background-color: var(--main-red);
    border-radius: 3px;
}

.row1 {
    width: 50%;
}

.row2 {
    width: 20%;
}

.row3 {
    width: 10%;
}

.row4 {
    width: 20%;
}

@media only screen and (max-width: 1000px) {
    .filter_input {
        max-width: 120px
    }
}


@media only screen and (max-width: 750px) {
    .filter_input {
        max-width: 100px
    }
}

@media only screen and (max-width: 500px) {
    .table_wrapper {
        margin: 15px !important;
    }

    .filter_input {
        max-width: 75px
    }
}

@media only screen and (max-width: 400px) {
    .filter_input {
        max-width: 75px
    }

    .title-add_button>* {
        padding: 5px !important;
    }
}

@media only screen and (max-width: 350px) {
    .filter_input {
        max-width: 75px
    }
}


@media only screen and (min-width: 900px) {
    .action_buttons {
        justify-content: space-around !important;
    }

    /* Third item of tabel */
    .body_item:nth-of-type(3) {
        padding-left: 0 !important;
    }
}

@media only screen and (min-width: 1000px) {
    .table_wrapper {
        width: 80%;
        margin: auto;
    }

    .filter_input {
        max-width: 175px
    }

    .table_icon {
        width: 30% !important;
    }

    .update_icon {
        margin-left: 15% !important;
    }

    .delete_icon {
        margin-right: 15% !important;
    }
}

@media only screen and (min-width: 1500px) {
    .table_icon {
        width: 20% !important;
    }

    .update_icon {
        margin-left: 25% !important;
    }

    .delete_icon {
        margin-right: 25% !important;
    }

    .head_item:nth-of-type(4) {
        padding-left: 0 !important;
    }
}