.file_wrapper {
    margin: 0 20px 20px 20px;
}

.alert_error {
    background-color: var(--main-red) !important;
    color: var(--main-white) !important;
    max-width: 800px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    font-size: var(--textFontSize);
}

.upload_title-cancel_button {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
}

.upload_wrapper {
    border-radius: 5px;
    padding: 20px 20px 5px 20px;
    width: 100%;
    box-shadow: var(--box-shadow);
}

.import_icon {
    margin: 0 5px;
}

.uploaded_file-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selected_file {
    margin: 0;
    padding: clamp(10px, calc(9px + 0.1vw), 15px);
    border-radius: 5px;
    font-weight: 500;
    border: 2px solid var(--inevvo-blue);
    font-size: var(--buttonFontSize);
    font-weight: 400 !important;
}

.not_selected_file {
    margin: 0;
}

@media only screen and (max-width: 500px) {
    .file_wrapper {
        margin: 15px !important;
    }
}

@media only screen and (max-width: 430px) {
    .uploaded_file-button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .selected_file {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 350px) {
    .file_wrapper {
        margin: 0 5px 5px 5px;
    }
}

@media only screen and (min-width: 1000px) {
    .file_wrapper {
        width: 80%;
        margin: auto;
    }
}