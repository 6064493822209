.pagination_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.previous_button,
.next_button {
    border: none;
    background-color: var(--main-white);
    cursor: pointer;
    font-size: var(--arrowFontSize);
    font-weight: 900 !important;
    opacity: 0.8;
}

.pages {
    border-radius: 50%;
    cursor: pointer;
    font-size: var(--tableFontSize);
    margin: 0 clamp(1px, calc(0px + 0.1vw), 10px);
    padding: clamp(2px, calc(0px + 0.5vw), 8px) clamp(4px, calc(3px + 1vw), 17px);
}

.test {
    padding: 0 !important;
    margin: 0 !important;
}

.activ_page {
    border: none;
    background-color: var(--inevvo-blue);
    color: var(--main-white);
}

.inactiv_page {
    background-color: var(--main-white);
    color: var(--main-black);
    border: none;
}

.previous_button {
    margin-left: clamp(4px, calc(3px + 1vw), 17px);
    margin-right: clamp(2px, calc(1px + 1vw), 10px);
}

.next_button {
    margin-right: clamp(4px, calc(3px + 1vw), 17px);
    margin-left: clamp(2px, calc(1px + 1vw), 10px);
}

.double_arrow {
    font-size: var(--arrowFontSize);
    font-weight: 900 !important;
    opacity: 0.8;
    cursor: pointer;
}

.inactiv {
    opacity: 0.3;
}