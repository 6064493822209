.sidebar_wrapper {
    display: flex;
    margin: 0 !important;
    height: 100vh;
}

.main_part {
    width: 100%;
    flex: 1 1 auto;
    overflow-y: auto;
}

.sidebar {
    background: var(--inevvo-blue);
    color: var(--main-white);
    height: 100vh;
    transition: all 0.5s;
    flex: 0 0 auto;
    margin-right: 1px !important;
    position: relative;
    width: 100%;
}

.sidebar_text {
    margin: 0;
    overflow-wrap: break-word !important;
    max-width: 85%;
}

.sidebar_item {
    display: flex;
    color: var(--main-white);
    padding: 5px 10px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
    align-items: center;
    margin-bottom: 10px;
    font-size: var(--sidebarItemFontSize);
    width: 100%;
}

.sidebar_language_selector {
    text-transform: capitalize !important;
}

.link {
    display: flex;
    color: #fff;
    gap: 15px;
    transition: all 0.5s;
}

.link:hover {
    background: var(--background-color-main);
    color: var(--main-white);
    transition: all 0.5s;
}

.sidebar_logout {
    border-top: 1px solid var(--main-white);
    border-bottom: 1px solid var(--main-white);
    cursor: pointer;
    margin-bottom: 40px;
}

.sidebar_logout:hover {
    background-color: var(--background-color-main);
}

.sidebar_logout,
.sidebar_user {
    padding: 30px 10px !important;
}

.active {
    color: var(--main-white);
    background: var(--background-color-main);
}

.toggle_button {
    position: absolute;
    bottom: 0px;
}

.toggle_button:hover {
    cursor: pointer;
}

/* in order to remove default down icon from react bootstrap dropdown menu */
.dropdown-toggle::after {
    display: none !important;
}

.language_dropdown {
    padding: 0px !important;
    background-color: transparent !important;
    border: 0px !important;
}

.dropdown_menu {
    border: 0px !important;
    box-shadow: var(--box-shadow) !important;
}

/* react bootstrap dropdown color change */
.custom-dropdown .dropdown-menu {
    background-color: transparent !important;
}

.custom-dropdown .dropdown-item {
    background-color: transparent !important;
}

.custom-dropdown .dropdown-item:focus,
.custom-dropdown .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
    /* Change the opacity as needed */
}

.sidebar_footer {
    display: flex;
    gap: 15px;
    transition: all 0.5s;
    align-items: center;
    margin-bottom: 10px;
    font-size: var(--sidebarItemFontSize);
}

.dropdown_item:hover {
    background-color: rgb(236, 252, 255) !important;
    color: var(--inevvo-blue) !important;
}

.dropdown_item:focus {
    background-color: var(--inevvo-blue) !important;
    color: white !important;
}

/* height */
@media only screen and (max-height: 450px) {
    .sidebar_item {
        padding: 5px !important;
        gap: 10px;
        margin: 0;
    }

    .sidebar_logout,
    .sidebar_user {
        padding: 10px 5px !important;
    }
}

/* width */

@media only screen and (max-width: 1250px) {
    .sidebar_item {
        padding: 10px !important;
        gap: 15px;
    }

    .sidebar_logout,
    .sidebar_user {
        padding: 20px 10px !important;
    }
}

@media only screen and (max-width: 750px) {
    .sidebar_item {
        padding: 5px !important;
        gap: 10px;
    }

    .sidebar_logout,
    .sidebar_user {
        padding: 20px 5px !important;
    }
}

@media only screen and (max-width: 500px) {
    .sidebar_item {
        padding: 5px !important;
        gap: 10px;
    }

    .sidebar_logout,
    .sidebar_user {
        padding: 20px 5px !important;
    }
}

@media only screen and (max-width: 350px) {
    .sidebar_item {
        padding: 5px !important;
        gap: 10px;
    }

    .sidebar_logout,
    .sidebar_user {
        padding: 20px 5px !important;
    }
}

@media only screen and (min-width: 1250px) {
    .sidebar_item {
        padding: 10px !important;
        gap: 15px;
    }

    .sidebar_logout,
    .sidebar_user {
        padding: 30px 10px !important;
    }
}