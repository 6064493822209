.input_group {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin-right: clamp(10px, calc(10px + 2vw), 40px);
  margin-bottom: clamp(10px, calc(10px + 2vw), 40px);
}

.input_item {
  border: none;
  border-bottom: 1px solid var(--main-black);
  background-color: var(--main-white);
  font-size: var(--tableFontSize) !important;
  padding-top: 5px;
}

.input_item:focus {
  outline: none;
}