.form_wrapper_delete {
    width: 100%;
    text-align: center;
}


.delete_alert {
    text-align: center;
}

.form_group {
    float: right;
}