.retrofit_wrapper {
    margin: 0 20px 20px 20px;
}

.id_label,
.id_number_input {
    font-size: var(--textFontSize) !important;
}

.retrofit_loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.retrofit_excel {
    display: inline !important;
}

.quantity_text_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: clamp(25px, 4vw, 35px);
    flex: 1;
}

.text_input_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: clamp(25px, 4vw, 35px);
}

.seperator_text {
    padding-left: 20px;
}

@media only screen and (max-width: 1600px) {
    .text_input_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
}

@media only screen and (max-width: 1500px) {
    .quantity_text_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
}

@media only screen and (min-width: 1000px) {
    .retrofit_wrapper {
        width: 80%;
        margin: auto;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 500px) {
    .retrofit_wrapper {
        margin: 15px;
    }
}