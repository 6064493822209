.overview_wrapper {
  margin: 0 20px 20px 20px;
  height: 100%;
}

.overview_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview_main {
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  padding: clamp(1px, calc(0px + 2vw), 25px);
}

.overview_inpuat_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.overview_user_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: clamp(15px, 3vw, 45px);
}

.overview_email_table {
  min-width: 250px;
  word-break: break-word;
}

.overview_button_group {
  float: right;
  margin-top: 5px;
}

.overview_wrapper>* {
  font-size: var(--textFontSize) !important;
}

@media only screen and (min-width: 1000px) {
  .overview_wrapper {
    width: 80%;
    margin: auto;
  }
}