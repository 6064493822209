.id_link_wrapper {
    margin: 0 20px 20px 20px;
}

.id_alert {
    max-width: 800px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
}

.id_image_text {
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    padding: 15px 15px 15px 5px;
    display: flex;
    justify-content: space-around;
    margin-bottom: clamp(25px, 3vw, 45px);
}

.icon_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.id_logo {
    width: clamp(35px, calc(35px + 9vw), 110px);
}

.id_icon {
    height: clamp(60px, calc(60px + 10vw), 150px);
    width: clamp(60px, calc(60px + 10vw), 150px);
}

.id_icon_text {
    font-size: clamp(6px, calc(6px + 1.1vw), 15px);
}

.box_text_title {
    color: var(--color);
    font-size: var(--boxTitleFontSize);
}

.id_text {
    color: var(--color);
    font-size: var(--textFontSize);
}

/******************* input part **********************/
.id_input_part {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    padding: 10px;
}

.manufacturer_select {
    margin-right: 100px;
    min-width: 260px;
    padding: 0;
}

.id_number_input {
    border: none;
    margin: 5px;
    border-bottom: 1px solid var(--main-black);
    background-color: var(--main-white);
    padding: 5px 10px;
    width: auto !important;
    max-width: 90%;
    display: block;
    min-width: 260px;
}

.id_number_input:focus {
    outline: none;
}

.id_label {
    padding-left: 15px;
    display: block;
}

/* to prevent arrows in input part */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.pdf_size {
    max-width: 40px;
    border: none;
    border: 1px solid var(--second-grey);
    border-radius: 5px;
    text-align: center;
    font-size: clamp(12px, calc(6px + 1.1vw), 15px);
}

/*************************** Button Part ************************/
.id_button_group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.id_button_group>* {
    margin: 0 0 0 10px;
}

/* *****************Historie********************* */
.history_wrapper {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    padding: 25px;
}

.history_table {
    border-top: 1px solid var(--second-grey);
}

.download_file:hover {
    cursor: pointer;
    background-color: var(--main-grey);
    color: white !important;
}

.history_checkbox {
    align-items: flex-start;
    text-align: left;
}

.id_link_wrapper>*,
.id_button_group {
    font-size: var(--textFontSize)
}

.display_downloaded_dimensions {
    font-size: var(--dimensionsFontSize);
}

@media only screen and (max-width: 1250px) {
    .id_input_part {
        flex-direction: column;
        align-items: start;
        gap: clamp(15px, 5vw, 30px);
    }

    .manufacturer_select {
        margin-right: 0px;
    }

    .id_button_group {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
    }

    .id_button_group>* {
        margin: 10px 20px;
    }
}

@media only screen and (max-width: 1000px) {
    .manufacturer_select {
        margin-right: 0px;
    }

    .id_button_group {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
    }

    .id_button_group>* {
        margin: 0;
    }
}

@media only screen and (max-width: 750px) {
    .history_wrapper {
        padding: 15px;
    }
}

@media only screen and (max-width: 500px) {
    .id_link_wrapper {
        margin: 15px;
    }

    .id_number_input {
        min-width: 220px;
    }

    .manufacturer_select {
        min-width: 220px;
        margin-right: 0px;
    }

    .history_wrapper {
        padding: 15px;
    }
}

@media only screen and (max-width: 350px) {
    .history_wrapper {
        padding: 15px;
    }
}

@media only screen and (min-width: 1000px) {
    .id_link_wrapper {
        width: 80%;
        margin: auto;
    }
}