.regenerate_wrapper {
    margin: 0 20px 20px 20px;
}

.file_label,
.seperator {
    display: block;
}

.id_label.file_label:nth-of-type(1),
.seperator {
    margin-top: 5%;
    margin-bottom: 5%;
}

.file_input {
    width: 80% !important;
    display: inline !important;
}

.regenerate_input_1 {
    flex: 2
}

.regenerate_input_2 {
    flex: 1
}

.preview_wrapper {
    margin: 10px;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 5px;
}

.preview_text {
    margin: 10px;
    text-align: center;
}

.preview_qr {
    padding: 10px;
    margin: auto;
    display: block;
    text-align: center;
    max-width: 350px;
    max-height: 350px;
}

.remove_file_icon {
    color: var(--inevvo-blue) !important;
    margin-left: 10px;
    cursor: pointer;
}

@media only screen and (min-width: 1000px) {
    .regenerate_wrapper {
        width: 80%;
        margin: auto;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 1250px) {
    .file_input {
        width: 100% !important;
    }

    .id_label.file_label:nth-of-type(1) {
        margin-top: 10%;
        margin-bottom: 10%;
    }

}

@media only screen and (max-width: 1200px) {
    .preview_qr {
        max-width: 30vw;
        max-height: 30vw;
    }
}

@media only screen and (max-width: 768px) {
    .preview_qr {
        max-width: 40vw;
        max-height: 40vw;
    }
}

@media only screen and (max-width: 500px) {
    .regenerate_wrapper {
        margin: 15px;
    }

    .preview_qr {
        max-width: 60vw;
        max-height: 60vw;
    }
}