.page_title_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.info_icon_1 {
    color: var(--inevvo-blue) !important;
    font-size: min(30px, calc(10px + 2vw)) !important;
    margin-right: 15px;
}

.info_icon_2 {
    color: var(--inevvo-blue) !important;
    font-size: min(30px, calc(10px + 2vw)) !important;
}