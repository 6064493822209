.footer_wrapper {
    background-color: var(--background-color-second);
    width: 100%;
    text-align: right;
    margin-top: auto;
    height: clamp(25px, 4vh, 55px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: clamp(3px, 1vw, 10px);
}

.footer_item {
    color: var(--inevvo-blue);
    text-decoration: none;
    cursor: pointer;
    font-size: var(--footerFontSize)
}

.version {
    font-size: 10px;
}

.footer_item:hover {
    color: var(--inevvo-blue);
}