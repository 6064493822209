.modal_header {
    font-size: var(--boxTitleFontSize) !important;
    font-weight: 400;
    border-bottom: 1px solid var(--third-grey) !important;
}

.modal_title {
    font-size: var(--textFontSize) !important;
}

.modal_button_groups {
    display: flex;
    justify-content: space-around !important;
}

.modal_button {
    background-color: var(--inevvo-blue) !important;
    border: none !important;
    width: 15%;
    font-size: var(--textFontSize) !important;
}

.modal_button:hover {
    background-color: var(--background-color-main) !important;
    border: none !important;
}

.modal_button:focus {
    transform: scale(0.9) !important;
    box-shadow: none !important;
}

.timer {
    font-size: var(--textFontSize) !important;
    text-align: center;
    border: none !important;
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    margin: auto;
    margin-bottom: 10px;
    padding: clamp(5px, 3vw, 12px) !important;

}