.dropzone_wrapper {
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    transition: ease-in-out 0.4s;
}

.dropzone_wrapper:hover {
    background-color: var(--background-color-second);
}

.upload_icon {
    height: 90px;
    color: var(--main-grey);
    margin-top: 20px;
}

.drop_text {
    text-align: center;
    margin: 20px 10px;
    font-size: var(--textFontSize) !important;
}

@media only screen and (max-width: 750px) {
    .upload_icon {
        height: 70px;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 500px) {
    .upload_icon {
        height: 60px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 350px) {
    .upload_icon {
        height: 50px;
        margin-top: 10px;
    }
}