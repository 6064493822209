.header_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 10px;
}

.logo {
    max-height: clamp(25px, calc(25px + 1vw), 42px);
}

.icon_text {
    padding: 0;
    margin: 0;
    font-size: clamp(10px, calc(11px + 0.6vw), 20px);
}

.icon_border {
    border-bottom: 2px solid var(--inevvo-green);
    padding: 0;
    margin-top: 10px;
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .header_wrapper {
        margin-bottom: 10px;
    }
}


@media only screen and (min-width: 800px) and (max-width: 1450px) {
    .icon_text {
        padding: 0;
        margin: 0;
        font-size: clamp(10px, calc(11px + 0.55vw), 19px);
    }
}