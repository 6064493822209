@font-face {
    font-family: "Roboto-Regular";
    src:
        url("./fonts/Roboto-Regular.eot"),
        url("./fonts/Roboto-Regular.woff2") format("woff2"),
        url("./fonts/Roboto-Regular.woff") format("woff"),
        url("./fonts/Roboto-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url("./fonts/Roboto-Medium.eot"),
        url("./fonts/Roboto-Medium.ttf") format("truetype"),
        url("./fonts/Roboto-Medium.eot?#iefix") format('embedded-opentype'),
        url("./fonts/Roboto-Medium.woff") format('woff'),
        url("./fonts/Roboto-Medium.woff2") format('woff2');
}

@font-face {
    font-family: 'Roboto-Light';
    src: url("./fonts/Roboto-Light.ttf") format("truetype"),
        url("./fonts/Roboto-Light.woff") format('woff'),
        url("./fonts/Roboto-Light.woff2") format('woff2');
}

#root {
    height: 100%;
    width: 100%;
}

:root {
    --fontWeigt: 300;
    --main-grey: #6F7071;
    --second-grey: rgb(145, 145, 145);
    --third-grey: #E0E0E0;
    --box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    --box-shadow-button: 0 0 1em gray;
    --box-shadow-general-button: 0 3px 3px 3px var(--third-grey);
    --box-shadow-cancel-button: 0 3px 3px 3px rgba(226, 226, 226, 0.7);
    --border-radius: 5px;
    --dark-blue: rgba(3, 1, 46, 0.85);
    --inevvo-blue: #5ab6f3;
    --background-color-main: lightskyblue;
    --background-color-second: #E7E7E7;
    --inevvo-green: #AFCB37;
    --main-black: #000;
    --main-white: #fff;
    --main-red: #dc3545;
    --second-border-color: #ccc;

    --sidebarItemFontSize: clamp(13px, calc(13px + 0.4vw), 20px);
    --titleFontSize: clamp(16px, calc(16px + 0.8vw), 36px);
    --boxTitleFontSize: clamp(15px, calc(14px + 0.7vw), 28px);
    --tableFontSize: clamp(8px, calc(6px + 1vw), 17px);
    --arrowFontSize: clamp(6px, calc(5px + 0.5vw), 12px);
    --textFontSize: clamp(13px, calc(13px + 0.4vw), 20px);
    --buttonFontSize: clamp(12px, calc(12px + 0.2vw), 15px);
    --footerFontSize: clamp(12px, 2vw, 16px);
    --inputFieldFontSize: clamp(9px, calc(9px + 0.4vw), 16px);
    --dimensionsFontSize: clamp(10px, 2vw, 16px);
}


*,
body,
#root,
body>* {
    font-family: "Roboto-Light" !important;
    font-weight: 300;
}

/***************************** button element *****************************/

.general_button {
    background-color: #5AB6F3 !important;
    border: none !important;
    font-size: var(--buttonFontSize);
    margin: 5px 0 5px 5px !important;
    padding: clamp(10px, calc(9px + 0.1vw), 15px) clamp(12px, calc(12px + 1vw), 25px) !important;
    font-weight: 400 !important;
    transition: all 0.4s ease-in-out;
}

.cancel_button {
    background-color: var(--third-grey) !important;
    color: #000 !important;
}

.delete_button {
    background-color: var(--main-red) !important;
}

.general_button:hover {
    border: none !important;
    box-shadow: var(--box-shadow-general-button);
    transform: scale(1.03) !important;
}

.cancel_button:hover {
    box-shadow: var(--box-shadow-cancel-button);
}

.box_title {
    color: var(--color);
    font-size: var(--titleFontSize);
    margin-bottom: 1rem;
}

.box_button {
    margin-bottom: 1rem;
}

.general_button:focus,
.cancel_button:focus,
.delete_button :focus {
    box-shadow: none !important;
}

Button {
    text-transform: uppercase !important;
}